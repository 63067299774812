import React from 'react'
import { Pane, Heading, Paragraph, Image } from '@muhsis/ui'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'

import { Layout, SEO, BackgroundImageGrid } from '../components'
import {
  Header,
  Footer,
  FeatureItem,
  PricingTable,
  SpotlightLight,
  SpotlightHeading,
  SpotlightDark,
  Support,
} from '../blocks'
import { imgPayVisa, imgPayMaster, imgPayAmex } from '../assets/images'
import {
  ilsBackups,
  ilsDocuments,
  ilsUpdates,
  ilsUser,
} from '../assets/illustrations'

const PricingPage = () => (
  <Layout>
    <SEO
      title="Fiyatlar"
      keywords={[
        `muhsis fiyatlar`,
        `web tabanlı ön muhasebe`,
        `muhasebe`,
        `web muhasebe programı`,
        `online muhasebe programı`,
      ]}
    />
    <BackgroundImageGrid>
      <Header />
      <SpotlightLight
        title="Bütçenize Uygun Fiyatlar"
        description="Aylık, 6 aylık ve 1 senelik planlarımızdan size en uygun olanı seçin. Muhsis'te sürpriz ücretler ile karşılaşmazsınız, ne ödeyeceğiniz önceden bellidir."
      />

      <PricingTable />

      <Grid>
        <Pane textAlign="center" marginBottom="2rem">
          <Paragraph marginBottom="1rem" color="muted">
            Geçerli ödeme yöntemleri
          </Paragraph>

          <Pane>
            <Image src={imgPayVisa} alt="" height={30} marginRight="0.25rem" />
            <Image
              src={imgPayMaster}
              alt=""
              height={30}
              marginRight="0.25rem"
            />
            <Image src={imgPayAmex} alt="" height={30} />
          </Pane>
        </Pane>
      </Grid>
    </BackgroundImageGrid>

    <Pane background="#F5FAFE" paddingTop="3rem" paddingBottom="4rem">
      <SpotlightHeading
        title="Tüm Paketlerimizde Bulunan Özellikler"
        description="Muhsis'te hangi paketi seçerseniz seçin, aşağıdaki özelliklerden faydalanabilirsiniz."
      />

      <Pane marginTop="2rem">
        <Grid>
          <Row>
            <Col sm={3}>
              <FeatureItem
                image={ilsUser}
                title="Sınırsız Kullanıcı"
                description="Muhsis'te kullanıcı başına fiyat ödemezsiniz. Tüm ekibinizi
                  davet edin, Muhsis'i hep beraber kullanın."
              />
            </Col>

            <Col sm={3}>
              <FeatureItem
                image={ilsDocuments}
                title="Sınırsız Belge & İşlem"
                description="Dilediğiniz kadar belge(fatura, irsaliye, vs.), ödeme,
              tahsilat, gider kaydı oluşturabilirsiniz. Muhsis'te işlem
              limiti yoktur."
              />
            </Col>

            <Col sm={3}>
              <FeatureItem
                image={ilsBackups}
                title="Günlük Yedekleme"
                description="Tüm veriler her gün düzenli aralıklar ile bizim tarafımızdan
              yedeklenir. Veri kaybı durumunuzda ekibimiz memnuniyetle size
              yardımcı olacaktır."
              />
            </Col>

            <Col sm={3}>
              <FeatureItem
                image={ilsUpdates}
                title="Ücretsiz & Düzenli Güncellemeler"
                description="Muhsis bulut tabanlı bir yazılım olduğu için tüm
              güncellemeleri anında kullanabilirsiniz. Uygulamaya eklenecek
              tüm yeni güncellemeler de fiyata dahildir."
              />
            </Col>
          </Row>
        </Grid>
      </Pane>
    </Pane>

    <SpotlightDark />

    <Pane paddingY="3rem">
      <SpotlightHeading
        title="Sıkça Sorulan Sorular"
        description="Kullanıcılarımızın en sık sordukları sorulara ve cevaplara buradan ulaşabilirsiniz."
      />

      <Grid>
        <Row>
          <Col sm={6}>
            <Pane marginBottom="1.5rem" marginRight="1.5rem" marginTop="2rem">
              <Heading size={500} fontSize="1.25rem" marginBottom="0.5rem">
                Muhsis'i nasıl satın alabilirim?
              </Heading>
              <Paragraph>
                Muhsis abonelik sistemi ile çalışmaktadır. 30 günlük ücretsiz
                deneme sürenizi başlattıktan sonra abonelik paketinizi
                istediğiniz zaman başlatabilirsiniz. Aboneliğinizi
                başlatmadığınız takdirde deneme sürenizin bitiminde hesabınıza
                erişiminiz kesilir.
              </Paragraph>
            </Pane>

            <Pane marginBottom="1.5rem" marginRight="1.5rem">
              <Heading size={500} fontSize="1.25rem" marginBottom="0.5rem">
                Ücretsiz kullanım sürem bittiğinde paketimi nasıl seçeceğim?
              </Heading>
              <Paragraph>
                Ücretsiz kullanım süreniz bitse bile e-posta adresi ve
                şifrenizle giriş yapabilir, paketinizi satın alabilirsiniz.
                Kredi kartı veya havale/EFT ile ödemenizi yaparak mevcut
                bilgileriniz ile kullanmaya devam edebilirsiniz.
              </Paragraph>
            </Pane>

            <Pane marginBottom="1.5rem" marginRight="1.5rem">
              <Heading size={500} fontSize="1.25rem" marginBottom="0.5rem">
                Abonelik ücretini Banka Havale/EFT yolu ile yapabilir miyim?
              </Heading>
              <Paragraph>
                6 Aylık ve Yıllık paket seçeneklerinde banka havalesi ile ödeme
                yapabilirsiniz. Havale işlemi sonrasında ilgili bilgileri
                destekmuhsis.com ‘a iletmeniz yeterli olacaktır. Aylık abonelik
                paketinde maalesef havale ile ödeme seçeneği yoktur.
              </Paragraph>
            </Pane>

            <Pane marginBottom="1.5rem" marginRight="1.5rem">
              <Heading size={500} fontSize="1.25rem" marginBottom="0.5rem">
                Aboneliğimi nasıl iptal edebilirim?
              </Heading>
              <Paragraph>
                Hesap işlemlerinden Aboneliğimi İptal et seçeneğini devreye
                sokarak, iptal sürecini başlatabilirsiniz. İptal işlemine onay
                vermeniz durumunda, abonelik sürenizin bitimine istinaden kredi
                kartınızdan herhangi bir çekim yapılmayacaktır. Abonelik
                sürenizin bitimine kadar kullanıma devam etme hakkınız
                olacaktır.
              </Paragraph>
            </Pane>

            <Pane marginBottom="1.5rem" marginRight="1.5rem">
              <Heading size={500} fontSize="1.25rem" marginBottom="0.5rem">
                Yedekleme yapmam gerekir mi?
              </Heading>
              <Paragraph>
                Muhsis, bulut mimari üzerinde çalışan bir yapı olduğu için
                ekstra bir yedekleme senaryosuna ihtiyaç duymadan ve abonelik
                paketinizin fiyatına dahil olarak her gün düzenli aralıklar ile
                yedeklenmektedir. Veri güvenliği ve gizliliği ekip olarak en
                önem verdiğimiz konuların başında gelir.
              </Paragraph>
            </Pane>
          </Col>

          <Col sm={6}>
            <Pane marginBottom="1.5rem" marginTop="2rem">
              <Heading size={500} fontSize="1.25rem" marginBottom="0.5rem">
                Ücretsiz kullanım sürem devam ederken abonelik paketini nasıl
                aktif ederim?
              </Heading>
              <Paragraph>
                Seçtiğiniz paketi değişmeksizin 30 günlük kullanımınız ücretsiz
                olacaktır. kullanırsınız. 30 günlük ücretsiz kullanımınız
                sırasında Muhsis hesabınıza giriş yaparak dilediğiniz paketi
                seçebilirsiniz, deneme sürenizin kalanı seçtiğiniz pakete
                eklenir.
              </Paragraph>
            </Pane>

            <Pane marginBottom="1.5rem">
              <Heading size={500} fontSize="1.25rem" marginBottom="0.5rem">
                Abonelik paket başlatma ödemesini nasıl yapabilirim?
              </Heading>
              <Paragraph>
                Paket seçim ekranında, size uygun olan abonelik paketini
                seçtikten sonra kredi kartı bilgilerinizi bir kere tanıtmanız
                yeterlidir. Muhsis seçtiğiniz abonelik paketine göre tahsilatı
                kredi kartınızdan yapacaktır.
              </Paragraph>
            </Pane>

            <Pane marginBottom="1.5rem">
              <Heading size={500} fontSize="1.25rem" marginBottom="0.5rem">
                Aboneliğimi nasıl yenileyebilirim?
              </Heading>
              <Paragraph>
                Kredi kartı ile başlatılan aboneliklerde yenileme otomatiktir.
                Aylık kullanım süreniz her bittiğinde tahsilat işlemi otomatik
                yapılır. Dilediğiniz zaman aboneliğinizi durdurabilirsiniz.
              </Paragraph>
            </Pane>

            <Pane marginBottom="1.5rem">
              <Heading size={500} fontSize="1.25rem" marginBottom="0.5rem">
                Kullanım için ekstra donanım ve yazılım maliyeti yapmam gerekir
                mi?
              </Heading>
              <Paragraph>
                Muhsis, bulut mimari ile tasarlanmış bir web uygulamasıdır. Bu
                sebeple internete giren herhangi bir cihazdan 7/24 erişim
                sağlanarak kullanılabilir. Herhangi bir yazılımın lisans
                bedelini satın almanızı talep etmez.
              </Paragraph>
            </Pane>

            <Pane marginBottom="1.5rem">
              <Heading size={500} fontSize="1.25rem" marginBottom="0.5rem">
                Verilerimi, Muhsis dışına aktarabilir miyim?
              </Heading>
              <Paragraph>
                Evet, tüm verilerinizi dilediğiniz zaman Excel(xls), CSV ve JSON
                formatlarında dışarı aktarabilirsiniz.
              </Paragraph>
            </Pane>
          </Col>
        </Row>
      </Grid>
    </Pane>

    <Pane background="#F5FAFE">
      <Support />
    </Pane>

    <Footer />
  </Layout>
)
export default PricingPage
